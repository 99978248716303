<template>
  <div class='KczxNav' v-if="$route.query.hidenav!='true' && loadAccount().role != '学生'">
    <div class="kczx-nav-box">
      <div 
      class="kczx-nav-item" 
      @click="onNavClick(index,item)" 
      v-for="(item,index) in navList" 
      :key="index"
      >
        <div class="icon nav-course" :class="[item.defaultClass,{isActive:item.isActive}]" :courseid="item.courseId">
          <div class="name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'KczxNav',
  props:[],
  components: {},
  data () {
    return {
      navList:[]
    }
  },
  computed: {},
  watch: {
    '$route.path':{
      handler:function(nVal){
        this.initNavActive()
      },
      immediate:true
    }
  },
  created () {
    // this.initNav()
    if(this.$route.query.hidenav != 'true' && this.loadAccount().role != '学生') {
      this.initNewNav()
    }
  },
  mounted () {
  },
  methods: {
    getNavList(data) {
      const navList = [
        {isActive:false,name:'同步课程',defaultClass:'Tbkc',path:'/Res/Tbkc'},
        {isActive:false,name:'同步测评',defaultClass:'Tbcp',path:'/Res/Tbcp'},
        {isActive:false,name:'精品卷库',defaultClass:'Jpjk',path:'/Res/Jpjk'},
        {isActive:false,name:'特色专题',defaultClass:'Tszt',path:'/Res/Tszt'},
        {isActive:false,name:'特色课程',defaultClass:'Tszt',path:'/Res/Tszt'},
        {isActive:false,name:'中考复习',defaultClass:'Zkfx',path:'/Res/Zkfx'},
        {isActive:false,name:'高考复习',defaultClass:'Gkfx',path:'/Res/Gkfx'},
        {isActive:false,name:'校本资源',defaultClass:'Jbzy',path:'/Res/Jbzy'},
        {isActive:false,name:'同步资源',defaultClass:'Tbzy',path:'/Res/Tbzy'},
      ];

      // 初中屏蔽同步测评
      if (this.getSunAccount('sc_period') == 2) {
        navList.splice(1,1);
      }

      const result = [];
      navList.forEach(item => {
        data.some(nav => {
          if (nav.name == item.name) {
            return result.push(Object.assign({}, nav, item));
          }
        });
      });

      return result;
    },
    initNewNav() {
      this.getCourseConfig().then(res=>{
          const data = res.data.data;
          if (this.isValidArray(data)) {
            this.navList = this.getNavList(data);
            this.$store.commit('UPDATE_LOADEND');
            this.initNavActive();
          }
      });
    },
    initNav(){
      let { role, zoneidx } = this.loadAccount(), path = this.$route.path;
      let period = this.getSessionVal('sc_period');
      debugger;
      if(role != "学生"){
        if(zoneidx == 62) {
          // 根据学段 -> 处理类型
          let idx = this.navList.findIndex( item => item.defaultClass == 'Tszt')
          this.navList[idx].name = period == 3 ? '特色专题' : '特色课程';
          // 添加中考复习
          if(period == 2) {
            this.navList.push({isActive:false,name:'中考复习',defaultClass:'Zkfx',path:'/Res/Zkfx'});
          }
          // 添加高考复习
          if(period == 3) {
            this.navList.push({isActive:false,name:'高考复习',defaultClass:'Gkfx',path:'/Res/Gkfx'});
          }
        }
        // 处理同步测评
        if (zoneidx != 62 && !(zoneidx >= 120490 && zoneidx <= 120494) && zoneidx != 121533 && zoneidx != 121346 && zoneidx != 120449 && zoneidx != 120276) {
          this.navList = [{isActive:false,name:'精品卷库',defaultClass:'Jpjk',path:'/Res/Jpjk'}];
          if (path == "/Res/Tbkc" || path == "/Res/Tbcp" || path == "/Res/Tszt") this.$router.push({ path: '/Res/Jpjk' });
        }

        if(zoneidx == 121346 || zoneidx == 120449 || zoneidx == 120276) {
          this.otherZoneNav(role, period);
          this.initNavActive();
          return ;
        }

        this.getCourseId().then((courseId)=>{
          let param = {
            ZoneID: this.getSessionVal("sc_zone"),
            CourseID: courseId,
            PeriodID: this.getSunAccount('sc_period'),
            SubjectID: this.getSunAccount('sc_subject'),
            SchoolID: this.getSunAccount('sc_school'),
          }
          let api = this.getMasterDataCenterUrl() + '/api/SeriesTextBook/GetSeriesCountQuery?' + $.param(param)
          this.$http.get(api).then(res=>{
              var count = res.data.data
              if(count > 0){
                this.navList.push({
                  isActive:false,
                  name:'校本资源',
                  defaultClass:'Jbzy',
                  path:'/Res/Jbzy'
                })
              }

              const url = this.getMasterDataCenterUrl() + '/api/Course/GetCourseConfig?' + $.param({ code: param.SchoolID});
              this.$http.get(url).then(result=>{
                const data = result.data.data;
                if (data?.length > 0) {
                  this.navList.push({
                    isActive: false,
                    name: '同步资源',
                    defaultClass: 'Tbzy',
                    path: '/Res/Tbzy'
                  })
                } else if (this.$route.path == "/Res/Tbzy") {
                  this.$router.push({ path: this.navList[0].path });
                }

                this.initNavActive();
              });
          })
        })
      }
    },
    initNavActive(){
      let path = this.$route.path
      this.navList.forEach(item=>{
        if(item.path == path||(this.$route.query.type&&this.$route.query.type == item.defaultClass)){
          item.isActive = true
        }else{
          item.isActive = false
        }
      })
    },
    onNavClick(index,item) {
      if(this.$route.path != this.navList[index].path){
        sessionStorage.setItem('courseID',JSON.stringify(item.courseId))
        this.$router.push({ path: this.navList[index].path })
        this.navList.forEach(item=>{
          item.isActive = false
        })
        this.navList[index].isActive = true
      }else{
        return
      }
    },
    getCourseId(){
      return new Promise((resolve,reject)=>{
        this.getCourseConfig().then(res=>{
            const data = res.data.data;
            if (this.isValidArray(data)) {
              let courseId = data.find(item => item.name == '同步测评').courseId;
              if (courseId) return resolve(courseId);
            }

            this.GetCourseInfoByName().then(courseId => resolve(courseId));
        });
      })
    },
    otherZoneNav(role, period) {
      debugger;
      if(role == '教师' && period == 2) {
        this.navList = [
          {isActive:true,name:'同步课程',defaultClass:'Tbkc',path:'/Res/Tbkc'},
          {isActive:false,name:'同步资源',defaultClass:'Tbzy',path:'/Res/Tbzy'},
          {isActive:false,name:'精品卷库',defaultClass:'Jpjk',path:'/Res/Jpjk'}
        ]
      } else if(role == '教师' && period == 3) {
        this.navList = [
          {isActive:false,name:'同步资源',defaultClass:'Tbzy',path:'/Res/Tbzy'},
          {isActive:false,name:'同步测评',defaultClass:'Tbcp',path:'/Res/Tbcp'},
          {isActive:false,name:'精品卷库',defaultClass:'Jpjk',path:'/Res/Jpjk'}
        ]
      } else if(role == '教师' && period == 1) {
        this.navList = [
          {isActive:true,name:'同步资源',defaultClass:'Tbzy',path:'/Res/Tbzy'},
          {isActive:false,name:'精品卷库',defaultClass:'Jpjk',path:'/Res/Jpjk'}
        ]
      }
    }
  }
}
</script>

<style lang='less' scoped>
    .KczxNav{
        min-width: 80px;
        background: #FFF;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        .kczx-nav-box{
          margin-top: 30px;
          .kczx-nav-item{
            cursor: pointer;
            .icon{
              border-radius: 2px;
              width: 60px;
              height: 64px;
              background-size: 200px;
              background-repeat: no-repeat;
              background-image: url(/static/images/res/sun-kczx/kczx-nav.png);
              background-position-x:-3px;
              margin: 0 auto;
              margin-bottom: 20px;
              color: #4A4A4A;
              .name{
                padding-bottom: 3px;
                height: 64px;
                width: 60px;
                text-align: center;
                vertical-align:bottom;
                display:table-cell;
                font-size: 12px;
              }
            }
            .isActive{
              background-position-x: -137px;
              color: #FFF;
            }
            .icon:hover{
              background-position-x: -70px;
            }
            .isActive:hover{
              .isActive()
            }
            .Tbkc{
              background-position-y: -2px;
            }
            .Tbcp{
              background-position-y: -74px;
            }
            .Jpjk{
              background-position-y: -146px;
            }
            .Gkfx{
              background-position-y: -215px;
            }
            .Tszt{
              background-position-y: -215px;
            }
            .Jbzy{
              background-position-y: -215px;
            }
          }
          }
    }
</style>
