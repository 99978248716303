import Routers from './router'
import App from './App'
import store from './store'
import qs from 'qs'
import cookie from 'js-cookie'
import api from './assets/js/api.js'
import util from './assets/js/util.js'
import './assets/css/console.css'
require('./assets/css/res/res.css')
require('./assets/css/site.css')
require('./assets/css/iseed.css')
require('./assets/css/course.css')
Vue.use(VueRouter)
Vue.use(util)
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$api = api
Vue.prototype.$qs = qs
Vue.prototype.$jquery = $
// Vue.prototype.session = 'ECF2E87483AAD28CE14DA7B29A'
import ResBus from 'vue-bus'
Vue.use(ResBus)
axios.interceptors.request.use((config) => {
    //store.commit('OPEN_LOADING')LoginSessionJson
    if(config.url.indexOf('LoginSessionJson') == -1){
        let account = JSON.parse(sessionStorage.account)
        // config.headers.session = 'C954535164992B507DB25E0A9B56'
        config.headers.session = account.session
    }
    return config
}, function(err) {
    return Promise.reject(err)
})
Vue.config.devtools = true
axios.interceptors.response.use((response) => {
    //store.commit('CLOSE_LOADING')
    return response
}, function(err) {
    return Promise.reject(err)
})

const RouterConfig = {
    mode: 'history',
    routes: Routers,
    // scrollBehavior (to, from, savedPosition) { // 解决vue路由跳转页面之后页面不从最顶端显示问题
    //     if (savedPosition) {
    //         return savedPosition
    //     } else {
    //         return { x: 0, y: 0 }
    //     }
    // }
}

const router = new VueRouter(RouterConfig)

router.beforeEach((to, from, next) => {
    Pace.start()
    let query = to.query
    let urlEmbed = query.embed
    if (urlEmbed && urlEmbed != '') {
        sessionStorage['embed'] = urlEmbed
    }
    let urlDevice = query.device
    if (urlDevice && urlDevice != '') {
        sessionStorage['device'] = urlDevice
    }
    let logout = query.logout
    if (logout == 1) {
        localStorage.removeItem("username")
        localStorage.removeItem("password")
    }
    let userAgent = navigator.userAgent
    let client = sessionStorage["device"]
    client = ((client && client != '') || (userAgent.indexOf('androidPadCKL') > 0))
    let account = cookie.getJSON('account') || JSON.parse(sessionStorage.getItem('account'))
    let pt = localStorage["platform"]
    if (to.path == '/' && pt && account && account.role == '学生') {
        pt = JSON.parse(pt)
        let alias = pt.CurrentSystemAlias
        if (!alias || alias == 'res') {
            next({
                path: '/UnAuth'
            })
        }
    }
    if (to.path == '/Account/SignIn' && client) {
        next({
            path: '/UnAuth'
        })
    } else {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (!(account && account.session)) {
                if (client) {
                    next({
                        path: '/UnAuth'
                    })
                } else {
                    Vue.prototype.gotoLogin();
                }
            } else {
                if (to.path == "/UnAuth") {
                    next()
                } else {
                    if (to.meta.baseAlias == "home" || to.meta.baseAlias == "cm" || to.meta.baseAlias == "zone") {
                        Vue.prototype.verifyPermissionsVisitCurrentHost(to.path, function(retCode) {
                            if (retCode && retCode > 0) {
                                next()
                            } else {
                                next("/UnAuth")
                            }
                        })
                    } else {
                        next()
                    }
                }
            }
        } else {
            if (to.path == "/UnAuth") {
                next()
            } else {
                if (account && account.session && to.meta.baseAlias == "home" || to.meta.baseAlias == "cm" || to.meta.baseAlias == "zone") {
                    Vue.prototype.verifyPermissionsVisitCurrentHost(to.path, function(retCode) {
                        if (retCode && retCode > 0) {
                            next()
                        } else {
                            next("/UnAuth")
                        }
                    })
                } else {
                    next()
                }
            }
        }
    }
})

router.afterEach((to, from, next) => {
    Pace.stop()
})

new Vue({
    el: '#app',
    router: router,
    store,
    template: '<App/>',
    components: {
        App
    },
    mounted() {
        // You'll need this for renderAfterDocumentEvent.
        document.dispatchEvent(new Event('render-event'))
    }
})